<template>
  <div class="relative overflow-hidden bg-red-500 lg:m-5 rounded-3xl">
    <div ref="ttfContainer"
         class="relative z-10 flex flex-col items-center justify-center pt-20 pb-6 lg:pb-16 lg:pt-32"
    >
      <Motion :animate="{ opacity: animation ? [0, 1] : 0 }"
              :transition="{ duration: 0.5 }"
              src="/placeholders/homepage/talking-logo.png"
              class="max-h-24 lg:max-h-full"
              tag="img"
      />
      <Motion v-if="title"
              :animate="{ opacity: animation ? [0, 1] : 0 }"
              :transition="{ duration: 0.5, delay: 0.1 }"
              class="max-w-2xl px-6 mt-10 text-3xl text-center text-white lg:mt-12 lg:text-6xl leading-tight lg:leading-[1.1]"
              tag="h2"
      >
        {{ title }}
      </Motion>
      <Motion v-if="title"
              :animate="{ opacity: animation ? [0, 1] : 0 }"
              :transition="{ duration: 0.5, delay: 0.2 }"
              class="max-w-xl px-6 mt-6 text-base text-center text-white lg:mt-8 lg:text-xl"
              tag="p"
      >
        {{ copy }}
      </Motion>
      <div class="relative flex justify-center w-full mt-20 mb-6 lg:mb-8 lg:mt-12">
        <div class="relative z-10">
          <img src="~/assets/img/ttf-promo.png"
               alt="Talking Tom and Friends"
               class="relative"
          >
          <Motion :initial="{ opacity: 0 }"
                  :animate="{
                    scale: animation ? [0, 1] : 0,
                    opacity: [0, 1],
                  }"
                  :transition="{ duration: 1.2, delay: 0.2, easing: spring() }"
                  class="absolute top-0 right-0 z-20 w-20 mr-16 overflow-hidden md:w-32 lg:w-52 md:mr- -mt-14 xl:-mr-44 lg:-mt-24"
          >
            <SvgHeyBubble class="" />
          </Motion>
        </div>

        <SvgCircle class="absolute w-full -mt-16 lg:-left-10 top-full -left-8 lg:-mt-52"
                   :class="cards && cards.length > 0 ? ' lg:max-w-xl' : 'max-w-xs lg:max-w-3xl'"
        />
      </div>

      <SectionTtfPromoList v-if="cards && cards.length > 0"
                           :items="cards"
                           class="mt-6 mb-6 lg:mb-8 lg:mt-8"
      />

      <ButtonBase v-if="cta"
                  :label="cta.title"
                  :type="cta.type"
                  :href="cta.href"
                  class="z-20 my-2 lg:mt-16 relative"
                  @click="handleClick(cta.href)"
      />
    </div>

    <Motion :initial="{ opacity: 0 }"
            :animate="{ scale: animation ? [0, 1] : 0, opacity: [0, 1] }"
            :transition="{ duration: 0.8, delay: 0.2, easing: spring() }"
            class="absolute top-0 w-20 -mt-2.5 ml-4 lg:w-40 lg:mt-28 lg:ml-28"
    >
      <SvgFlower />
    </Motion>
    <Motion :initial="{ opacity: 0 }"
            :animate="{ scale: animation ? [0, 1] : 0, opacity: [0, 1] }"
            :transition="{ duration: 1, delay: 0.6, easing: spring() }"
            class="absolute top-0 right-0 w-24 -mt-2.5 -mr-2 lg:w-56 lg:mr-56 lg:-mt-8"
    >
      <SvgFlower />
    </Motion>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js'
import { inView, spring } from 'motion'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useNuxtApp } from '#imports'

defineProps({
  title: {
    type: String,
    default: ''
  },
  copy: {
    type: String,
    default: ''
  },
  cta: {
    type: [Object, Boolean],
    default: false
  },
  cards: {
    type: Array,
    default: () => []
  }
})

const { $event } = useNuxtApp()
const route = useRoute()

const ttfContainer = ref(null)
const animation = ref(false)

function handleClick(linkTitle: string) {
  if (route.path.includes('our-work')) return $event('Link', { props: { 'Our work page': linkTitle } })
}

onMounted(() => {
  inView(
    ttfContainer.value,
    () => {
      animation.value = true
    },
    { amount: 0.2 }
  )
})
</script>
